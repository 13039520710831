.side-bar-img {
  float: left;
  width: 70%;
  margin-left: 15%;
  margin-top: 20px;
  margin-bottom: 20px;
  object-fit: contain;
}

.empty-layout,
.empty-layout-body {
  padding-top: 0.7rem;
  margin: 0 !important;
  width: 100%;
  text-align: center;
  margin: 0 auto;
}

.empty-top-bar,
.empty-top-bar>[class^="col-"] {
  height: 5rem;
  padding: 0;
}

.empty-top-bar img {
  text-align: center;
  margin: 0 auto;
}

.empty-layout-img {
  float: left;
  height: 100%;
}

.MuiAppBar-colorPrimary {
  background-color: white !important;
}

.my-app-bar .MuiIconButton-root:hover {
  background-color: transparent;
}

.my-app-bar {
  box-shadow: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.side-bar-menu-container {
  text-align: left;
}

.side-bar .MuiListItem-button:hover {
  background-color: transparent !important;
}

.side-bar .MuiListItem-button:hover .MuiListItemText-root {
  color: #f26522 !important;
  transition: color 0.3s;
}

.side-bar-menu-item .MuiListItemIcon-root {
  min-width: 35px;
}

.side-bar-menu-item .MuiListItemText-root {
  font-weight: 400;
  font-size: 14px !important;
  color: #c1c7d0;
  padding-left: 10px;
}

.side-bar-menu-item-selected {
  background-color: lightgray !important;
}

.side-bar-bottom-container {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 10px;
}

.no-outline:focus {
  outline: none;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.table-selection tr>td:first-child input,
.table-selection tr>th:first-child input {
  -ms-transform: scale(1.5);
  /* IE */
  -moz-transform: scale(1.5);
  /* FF */
  -webkit-transform: scale(1.5);
  /* Safari and Chrome */
  -o-transform: scale(1.5);
  /* Opera */
  transform: scale(1.5);
}

.table-selection tr>td:first-child,
.table-selection tr>th:first-child {
  text-align: center;
}

.form-label {
  font-weight: bold;
}

/* .thumbnail {
  width: 100%;
  height: 195px;
  background-color: lightgray;
  border: 1px solid lightgray;
  border-radius: 5px;
}
.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border: 5px solid white;
  border-radius: 5px;
} */
// .empty-layout {
// }
// .blue-bg {
//   color: #00476f;
// }
// .orange-bg {
//   color: #f26522;
// }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.preview-content img,
.preview-content iframe {
  width: 100%;
  border: 1px solid lightgray;
}

.preview-content iframe {
  height: 30rem;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

@media only screen and (max-device-width: 640px) {
  .preview-content iframe[src^='https://www.youtube.com/'] {
    height: 15rem;
  }
}

.checkbox-right {
  position: relative;
}

.checkbox-right .form-check {
  position: absolute;
  top: -0.2rem;
  right: -2rem;
}

.global-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1000000;
}

.global-loading>center {
  margin-top: 20rem;
}

.word-wrap {
  word-wrap: break-word;
  white-space: normal;
}

.MuiPaginationItem-textPrimary.Mui-selected {
  background-color: #F26522 !important;
}

.id-container {
  max-height: 5rem;
  overflow: auto;
}

.id-container td {
  text-align: center;
  padding-top: 0.3rem !important;
  padding-bottom: 0.3rem !important;
}

.url {
  color: blue;
  cursor: pointer;
}

.no-padding {
  padding: 0px !important;
}

.no-magin {
  margin: 0px !important;
}
.btn-primary{
  background-color: #F26522 !important;
  border-color: #F26522 !important;
}
